import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

import { about, img, article } from "../styles/about.module.css"

export default function About({ data }) {
  const image = data.file
  const aboutImage = getImage(image)

  return (
    <Layout>
      <section className={`${about} container`}>
        <aside className={img}>
          <GatsbyImage image={aboutImage} alt="Katinka profile picture" />
        </aside>
        <article className={article}>
          <h1>Studio KATHAN</h1>
          <p>
            Meet Katinka Vermeylen (°1992), aka Studio KATHAN. This Antwerp
            based inspiring creative was born and raised in the vast fields of
            de Kempen. Growing up in this area, she learned the importance of
            finding inspiration and appreciation in the small things in life.
            Her studies added on to this, and in a few years’ time she got her
            degree and became a Master of Arts (M.A.) in graphic
            design/advertising design.
          </p>
          <p>
            In her work, you can find Katinka at the intersection of many
            creative and inspiring roads. She is constantly upping her game by
            exploring whatever sparks her interest, which is mostly fashion,
            design, and aesthetics. Ever evolving, Katinka has her eyes set on
            becoming a versatile and multidisciplinary artist. She aims to make
            Studio KATHAN an all-round design studio, balancing a taste for the
            arts and with the joy of fruitful collaborations. At the root, you’ll
            find fair production and sustainable materials, two very important
            pillars of whatever it is that Katinka’s working on.
          </p>
          <p>
            From fabrics to colours, lines to shapes, or just a vaguely distinct
            idea: it excites and inspires her to her very core. Katinka is a
            designer and photographer that feels all her work, which makes it by
            definition truly unique.
          </p>
          <p>
            Of course, nothing sparks artistry and creativity more than the things that make you 	
            happy. Katinka loves to be surrounded by friends and family and looks for a new challenge 
            in everything. She likes to discover new places and tries to keep up the sporty spice 
            factor. She also enjoys to dance around and experiment in the kitchen. She happily shares 
            her life with Bert, Antwerp based architect, the perfect match in creative and loving 
            ways.
          </p>
        </article>
      </section>
    </Layout>
  )
}

export const queyr = graphql`
  query AboutImage {
    file(relativeDirectory: { eq: "static" }, name: { eq: "about" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
